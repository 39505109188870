import { lazy } from 'react';
// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import CategoriesManagement from 'views/categoriesManagement';
import { ACTION } from 'views/userManagement/admins/constants/variables';
import TransactionManagement from 'views/transactionManagement';
import TransactionDetail from 'views/transactionManagement/details';

// dashboard routing
const Dashboard = Loadable(lazy(() => import('views/dashboard')));
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/default')));

// User managemenet routing
const AdminList = Loadable(lazy(() => import('views/userManagement/admins')));
const AdminAdd = Loadable(lazy(() => import('views/userManagement/admins/AddProfile')));
const AdminProfile = Loadable(lazy(() => import('views/profile')));
const AdminUserProfile = Loadable(lazy(() => import('views/userManagement/admins/profile')));
const AdminAccountProfile = Loadable(lazy(() => import('views/profile/user-profile')));
const AdminChangePassword = Loadable(lazy(() => import('views/profile/change-password')));
const UserList = Loadable(lazy(() => import('views/userManagement/users')));
const UserProfile = Loadable(lazy(() => import('views/userManagement/users/profile')));
const AppUserList = Loadable(lazy(() => import('views/userManagement/appUsers')));
const AppUserProfile = Loadable(lazy(() => import('views/userManagement/appUsers/profile')));
const AddAppUser = Loadable(lazy(() => import('views/userManagement/appUsers/forms/AddAppUser')));
const EditAppUser = Loadable(lazy(() => import('views/userManagement/appUsers/forms/EditAppUser')));

const ClientUserList = Loadable(lazy(() => import('views/userManagement/clientUsers')));
const ClientUserProfile = Loadable(lazy(() => import('views/userManagement/clientUsers/profile')));
const AddClientUser = Loadable(
  lazy(() => import('views/userManagement/clientUsers/forms/AddAppUser'))
);
const EditClientUser = Loadable(
  lazy(() => import('views/userManagement/clientUsers/forms/EditAppUser'))
);

/* email template */
const EmailTemplateList = Loadable(lazy(() => import('views/emailTemplate')));
const AddEmailTemplate = Loadable(lazy(() => import('views/emailTemplate/forms/addTemplate')));
const EditEmailTemplate = Loadable(lazy(() => import('views/emailTemplate/forms/editTemplate')));

// Error routing
// const ErrorPage = Loadable(lazy(() => import('views/pages/maintenance/error')));
// page management routing
const PageList = Loadable(lazy(() => import('views/pageManagement')));

const AddPage = Loadable(lazy(() => import('views/pageManagement/forms/addPage')));
const EditPage = Loadable(lazy(() => import('views/pageManagement/forms/editPage')));

const ReviewList = Loadable(lazy(() => import('views/reviews')));
const ReviewDetails = Loadable(lazy(() => import('views/reviews/reviewDetails')));

const ClaimReceipts = Loadable(lazy(() => import('views/claimReceipt/index')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: 'dashboard',
      element: <Dashboard />
    },
    {
      path: 'default',
      element: <DashboardDefault />
    },
    {
      path: 'user/list',
      element: <UserList />
    },
    {
      path: 'user/profile/:id',
      element: <UserProfile />
    },
    {
      path: 'app-user/list',
      element: <AppUserList />
    },
    {
      path: 'app-user/add',
      element: <AddAppUser />
    },
    {
      path: 'app-user/edit/:id',
      element: <EditAppUser />
    },
    {
      path: 'app-user/profile/:id',
      element: <AppUserProfile />,
      allowedRoles: ACTION.VIEW_THERAPIST_DETAIL
    },
    {
      path: 'client-user/list',
      element: <ClientUserList />
    },
    {
      path: 'client-user/add',
      element: <AddClientUser />
    },
    {
      path: 'client-user/edit/:id',
      element: <EditClientUser />
    },
    {
      path: 'client-user/profile/:id',
      element: <ClientUserProfile />,
      allowedRoles: ACTION.VIEW_CLIENT_DETAIL
    },
    {
      path: 'admin/list',
      element: <AdminList />
    },
    {
      path: 'admin/add',
      element: <AdminAdd />,
      allowedRoles: ACTION.ADMIN_ADD
    },
    {
      path: 'admin/profile',
      element: <AdminProfile />
    },
    {
      path: 'admin/profile/:id',
      element: <AdminUserProfile />,
      allowedRoles: ACTION.ADMIN_EDIT
    },
    {
      path: 'admin/account-profile',
      element: <AdminAccountProfile />
    },
    {
      path: 'admin/change-password',
      element: <AdminChangePassword />
    },
    {
      path: 'email-template/list',
      element: <EmailTemplateList />
    },
    {
      path: 'email-template/add',
      element: <AddEmailTemplate />
    },
    {
      path: 'email-template/edit/:id',
      element: <EditEmailTemplate />,
      allowedRoles: ACTION.EMAIL_TEMPLATE_MANAGEMENT_EDIT
    },

    {
      path: 'page-management/add',
      element: <AddPage />
    },
    {
      path: 'page-management/edit/:id',
      element: <EditPage />,
      allowedRoles: ACTION.PAGE_MANAGEMENT_EDIT
    },
    {
      path: 'page-management/list',
      element: <PageList />
    },
    {
      path: 'categories-management/list',
      element: <CategoriesManagement />
    },
    {
      path: 'transaction-management/list',
      element: <TransactionManagement />,
      allowedRoles: ACTION.TRANSACTION_LIST
    },

    {
      path: 'transaction-management/detail',
      element: <TransactionDetail />,
      allowedRoles: ACTION.TRANSACTION_LIST
    },
    {
      path: 'reviews-management/list',
      element: <ReviewList />,
      allowedRoles: ACTION.REVIEW_LIST
    },
    {
      path: 'reviews-management/review-detail/:getReviewId',
      element: <ReviewDetails />,
      allowedRoles: ACTION.REVIEW_LIST
    },
    {
      path: 'claim-receipts/list',
      element: <ClaimReceipts />,
      allowedRoles: ACTION.CLAIM_RECEIPTS_LIST
    }
  ]
};

export default MainRoutes;
