import { gql } from '@apollo/client';

export const LIST_CLAIM_RECEIPT = gql`
  query ClaimReceiptList($input: GetClaimReceiptListDTO!) {
    claimReceiptList(input: $input) {
      data {
        _id
        invoiceNumber
        totalAmount
        claimPdfKey
        appointment {
          startTime
          _id
        }
        client {
          firstName
          lastName
        }
        therapist {
          firstName
          lastName
        }
      }
      message
      pagination {
        hasNextPage
        total
      }
    }
  }
`;
