import { FormattedMessage } from 'react-intl';

import { IconTransaction } from 'constants/icons';
import { NavItemType } from 'types';
import { ACTION } from 'views/userManagement/admins/constants/variables';

const claimedReceiptManagement: NavItemType = {
  id: 'claimed-receipt-management',
  title: <FormattedMessage id="Claim Receipt Management" />,
  icon: IconTransaction,
  type: 'group',
  url: 'claim-receipts/list',
  allowedRoles: ACTION.CLAIM_RECEIPTS_LIST
};

export default claimedReceiptManagement;
