/* eslint-disable import/prefer-default-export */

import { HeadCell } from 'types';

// table header options
export const headCells: HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    label: 'Full Name',
    align: 'left'
  },

  {
    id: 'email',
    numeric: false,
    label: 'Email',
    align: 'left'
  },
  {
    id: 'createdAt',
    numeric: false,
    label: 'Date joined',
    align: 'left'
  },
  {
    id: 'status',
    numeric: false,
    label: 'Admin access',
    align: 'left'
  },
  {
    id: 'action',
    numeric: false,
    label: 'Action ',
    align: 'left'
  }
];

export const defaultAdmin = {
  _id: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  createdAt: '',
  status: '',
  dialCode: '',
  role: ''
};

export enum ACTION {
  VIEW_ADMIN_LIST = 'view-admin-list',
  VIEW_ADMIN_DETAIL = 'view-admin-detail',
  ADMIN_EDIT = 'admin-edit',
  ADMIN_ACCESS_EDIT = 'admin-access-edit',
  REVIEW_LIST = 'review-list',
  TRANSACTION_LIST = 'transaction-list',
  ADMIN_ADD = 'admin-add',
  ADMIN_DELETE = 'admin-delete',
  ADMIN_ENABLE_DISABLE = 'admin-enable-disable',
  CLIENT_ENABLE_DISABLE = 'client-enable-disable',
  THERAPIST_ENABLE_DISABLE = 'therapist-enable-disable',
  THERAPIST_APPROVE_DECLINE = 'therapist-approve-decline',
  CLIENT_DELETE = 'client-delete',
  THERAPIST_DELETE = 'therapist-delete',
  // VIEW_CLIENT_LIST = 'view-client-list',
  VIEW_CLIENT_DETAIL = 'view-client-detail',
  VIEW_THERAPIST_DETAIL = 'view-therapist-detail',
  // VIEW_THERAPIST_LIST = 'view-therapist-list',
  // PAGE_MANAGEMENT_LIST = 'page-management-list',
  PAGE_MANAGEMENT_EDIT = 'page-management-edit',
  // EMAIL_TEMPLATE_MANAGEMENT_LIST = 'email-template-list',
  EMAIL_TEMPLATE_MANAGEMENT_EDIT = 'email-template-edit',
  CATEGORY_EDIT = 'category-edit',
  CLAIM_RECEIPTS_LIST = 'claim-receipt-list'
}

export const rolesArray = [
  {
    label: 'Allow this admin to edit other admins details',
    value: 'admin-edit'
  },
  {
    label: 'Allow this admin to edit other admins access',
    value: 'admin-access-edit'
  },
  {
    label: 'Allow this admin to view transactions',
    value: 'transaction-list'
  },
  {
    label: 'Allow this admin to view reviews of therapists',
    value: 'review-list'
  },
  {
    label: 'Allow this admin to edit email template',
    value: 'email-template-edit'
  },
  {
    label: 'Allow this admin to edit page content',
    value: 'page-management-edit'
  },
  {
    label: 'Allow this admin to view clients detail',
    value: 'view-client-detail'
  },
  {
    label: 'Allow this admin to view therapists detail',
    value: 'view-therapist-detail'
  },
  {
    label: 'Allow this admin to enable/disable other admin',
    value: 'admin-enable-disable'
  },
  {
    label: 'Allow this admin to enable/disable  clients',
    value: 'client-enable-disable'
  },
  {
    label: 'Allow this admin to enable/disable  therapists',
    value: 'therapist-enable-disable'
  },
  {
    label: 'Allow this admin to approve/decline  therapists',
    value: 'therapist-approve-decline'
  },
  {
    label: 'Allow this admin to delete clients',
    value: 'client-delete'
  },
  {
    label: 'Allow this admin to delete therapists',
    value: 'therapist-delete'
  },
  {
    label: 'Allow this admin to delete admins',
    value: 'admin-delete'
  },
  {
    label: 'Allow this admin to add admins',
    value: 'admin-add'
  },
  {
    label: 'Allow this admin to add categories',
    value: 'category-edit'
  },
  {
    label: 'Allow this admin to view claim receipts',
    value: 'claim-receipt-list'
  }
];

export const title = 'Delete Admin';
export const description = 'Are you sure you want to delete ?';

export enum AdminStatus {
  ENABLED = 'enabled',
  DISABLED = 'disabled'
}

export enum AdminAccess {
  ADMIN_LIMITED_ACCESS = 'Admin-Limited access',
  ADMIN_FULL_ACCESS = 'Admin-Full access'
}
