// ==============================|| MENU ITEMS ||============================== //

import dashboard from './dashboard';
import userManagement from './userManagement';
import { NavItemType } from 'types';
import emailTemplateManagement from './emailTemplateManagement';
import PageManagement from './pageManagement';
import reviewsManagement from './reviewsManagement';
import transactionManagement from './transactionManagement';
import categoriesManagement from './categoriesManagement';
import claimedReceiptManagement from './claimedReceiptManagement';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [
    dashboard,
    userManagement,
    transactionManagement,
    reviewsManagement,
    claimedReceiptManagement,
    categoriesManagement,
    PageManagement,
    emailTemplateManagement
  ]
};

export default menuItems;
